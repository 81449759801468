import { SiTailwindcss } from "react-icons/si";

export const TailwindcssTech = () => {
  return (
    <div>
      <SiTailwindcss />
      <h4>TailwindCSS</h4>
    </div>
  );
};
