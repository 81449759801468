import { SiNextDotJs } from "react-icons/si";

export const NextJSTech = () => {
  return (
    <div>
      <SiNextDotJs />
      <h4>NextJs</h4>
    </div>
  );
};
